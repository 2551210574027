<template>
    <div>
      <ServerError v-if="ServerError" />
      <vue-element-loading
        :active="appLoading"
        spinner="bar-fade-scale"
        color="black"
        size="128"
        is-full-screen
      />
      <v-layout wrap justify-start pt-4 px-2>
        <v-flex xs12>
          <v-layout pt-3 wrap justify-start>
            <v-flex xs7 sm3 md3 lg2 xl2>
              <v-layout wrap justify-center>
                <v-flex xs9 sm10 text-left align-self-center>
                  <span
                    style="
                      font-family: poppinssemibold;
                      font-size: 20px;
                      color: #1c1c1c;
                    "
                  >
                    Order Report
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 sm3 md2 text-right pa-2>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="from"
                    label="From Date"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="from"
                  no-title
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm3 md2 text-right pa-2>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="to"
                    label="To Date"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="to"
                  no-title
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <!-- <v-flex xs12 sm3 md2 pa-2>
              <v-select
                dense
                :items="shop"
                v-model="seller"
                label="Seller List"
                outlined
                item-text="shopName"
                item-value="value"
              ></v-select>
            </v-flex> -->
            <v-flex xs12 sm3 md2 pa-2>
              <v-select
                dense
                :items="items"
                v-model="orderStatus"
                label="Order Status"
                outlined
                item-text="name"
                item-value="value"
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6 md2 lg2 xl2 text-right pa-2>
          <v-btn color="#30B868">
            <a
              target="_blank"
              :href="
                'https://ecoshopapi.mudumalaitigerreserve.com/order/summary/pdf?fromDate=' +
                fromDate +
                '&toDate=' +
                toDate +
                '&sellerId=' +
                seller +
                '&orderStatus=' +
                orderStatus
              "
              class="downlink"
            >
              <span
                style="
                  font-size: 14px;
                  font-family: poppinssemibold;
                  color: #fff;
                "
              >
                Download PDF</span
              >
            </a>
          </v-btn>
        </v-flex>
        <v-flex xs12 sm6 md2 lg2 xl2 text-right pa-2>
          <v-btn color="#30B868">
            <a
              target="_blank"
              :href="
                'https://ecoshopapi.mudumalaitigerreserve.com/order/summary/excel?fromDate=' +
                fromDate +
                '&toDate=' +
                toDate +
                '&sellerId=' +
                seller +
                '&orderStatus=' +
                orderStatus
              "
              class="downlink"
            >
              <span
                style="
                  font-size: 14px;
                  font-family: poppinssemibold;
                  color: #fff;
                "
              >
                Download Excel</span
              >
            </a>
          </v-btn>
        </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center pt-5 v-if="purchase.length>0">
        <v-flex xs12 sm11 style="font-family: poppinsregular">
          <v-data-table
            :headers="headers"
            :items="purchase"
            :items-per-page="limit"
            hide-default-footer
            class="elevation-1 .text--black"
            id="virtual-scroll-table"
          >
            <template v-slot:[`item.create_date`]="{ item }">
              <span v-if="item.create_date">{{
                item.create_date.slice(0, 10)
              }}</span>
            </template>
            <template v-slot:[`item._id`]="{ item }">
              <v-icon small class="mr-2" @click="info(item)"> mdi-eye </v-icon>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center py-4 v-for="(item, i) in orders" :key="i">
        <!-- <v-flex xs12 sm11 text-left pb-4 pl-2>
          <span
            style="font-family: opensansbold; font-size: 20px; color: #808080"
          >
            {{ formatDate(item.create_date) }}
          </span>
        </v-flex> -->
        <v-layout wrap justify-center>
          <v-flex xs12 sm11>
            <v-card>
              <v-layout wrap justify-star py-3 px-5>
                <v-flex xs12 py-2>
                  <v-layout wrap justify-space-between>
                    <v-flex text-left>
                      <v-layout wrap>
                        <v-flex xs12>
                          <span
                            style="color: #7e8f85; font-family: poppinsregular"
                            >Order Date
                          </span>
                        </v-flex>
                        <v-flex xs12>
                          <span style="color: #000000; font-family: poppinsmedium"
                            >{{ formatDate(item.create_date) }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex text-center>
                      <v-layout wrap>
                        <v-flex xs12>
                          <span
                            style="color: #7e8f85; font-family: poppinsregular"
                            >Order Number
                          </span>
                        </v-flex>
                        <v-flex xs12>
                          <span style="color: #000000; font-family: poppinsmedium"
                            >{{ item.orderId }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex text-right>
                      <v-layout wrap justify-end>
                        <v-flex xs12>
                          <span
                            style="color: #7e8f85; font-family: poppinsregular"
                            >Order Status
                          </span>
                        </v-flex>
                        <v-flex xs12>
                          <span
                            style="color: #30b868; font-family: poppinsmedium"
                            >{{ item.orderStatus }}</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 py-4>
                  <v-divider></v-divider>
                </v-flex>
                <v-flex
                  xs12
                  sm12
                  md12
                  lg12
                  xl12
                  py-2
                  text-start
                  v-for="(transaction, i) in item.orderDetails"
                  :key="i"
                >
                  <v-layout wrap>
                    <v-flex xs1 v-if="transaction.productId">
                      <v-img
                        :src="mediaUURL + transaction.productId.photos[0]"
                        height="100px"
                        contain
                        :alt="transaction.name"
                      >
                        <template v-slot:placeholder>
                          <ImageLoader />
                        </template>
                      </v-img>
                    </v-flex>
                    <v-flex xs9>
                      <v-layout wrap justify-center fill-height>
                        <v-flex xs12 sm12 md12 align-self-center pl-2 text-left>
                          <v-layout
                            wrap
                            justify-start
                            style="line-height: 16px"
                            fill-height
                            v-if="transaction.productId"
                          >
                            <v-flex xs12>
                              <span
                                style="
                                  font-family: poppinssemibold;
                                  font-size: 15px;
                                  color: #191919;
                                "
                              >
                                {{ transaction.productId.productname }}
                                <!-- <span v-if="list.name.length > 25"> ... </span> -->
                              </span>
                            </v-flex>
                            <v-flex xs12 pt-3 v-if="transaction.itemId">
                              <span
                                style="
                                  color: #191919;
                                  font-size: 13px;
                                  font-family: poppinsmedium;
                                "
                                >Size
                                <span style="font-family: poppinsregular"
                                  >: {{ transaction.itemId.size }}</span
                                >
                              </span>
                            </v-flex>
                            <v-flex xs12 pt-1>
                              <span
                                style="
                                  color: #191919;
                                  font-size: 13px;
                                  font-family: poppinsmedium;
                                "
                                >OTY
                                <span style="font-family: poppinsregular"
                                  >: {{ transaction.quantity }}
                                </span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 py-2>
                  <v-divider></v-divider>
                </v-flex>
                <!-- <v-flex xs12 sm6 text-left>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-btn
                        color="#30B868"
                        dark
                        :to="'/orderDetails?id=' + item._id"
                        ><span
                          style="
                            font-family: opensanssemibold;
                            font-size: 13px;
                            color: white;
                          "
                          >VIEW ORDER</span
                        ></v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-flex> -->
                <v-flex xs12 sm12 text-right>
                  <v-layout wrap justify-end>
                    <v-flex xs12 pb-3 v-if="item.isPromoCodeApplied">
                      <span
                        style="
                          color: #3d3b3b;
                          font-family: poppinsregular;
                          font-size: 14px;
                        "
                        >Promo Code Applied :</span
                      >
                      <span style="color: #3d3b3b; font-family: poppinsregular"
                        >₹ {{ item.couponDiscount }}</span
                      >
                    </v-flex>
                    <v-flex xs12 pb-4>
                      <span
                        style="
                          color: #3d3b3b;
                          font-family: poppinsregular;
                          font-size: 14px;
                        "
                        >Total Amount :
                      </span>
                      <span style="color: #383838; font-family: poppinsmedium"
                        >₹ {{ item.subTotal }}</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-layout>
      <v-layout wrap justify-center pt-2>
        <v-flex xs12>
          <div class="text-center pb-5" v-if="pages > 1">
            <v-pagination
              :length="pages"
              :total-visible="7"
              v-model="currentPage"
              color="green"
              circle
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center pt-5 v-if="orders.length < 1">
        <v-flex xs12 text-center>
          <span
            style="font-family: poppinsregular; font-size: 25px; color: #000000"
          >
            Oops! No Data Found
          </span>
        </v-flex>
      </v-layout>
    </div>
  </template>
    <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        timeout: 5000,
        msg: "",
        ServerError: false,
        showSnackBar: false,
        page: 1,
        currentPage: 1,
        pages: 0,
        limit: 10,
        menu1: false,
        menu2: false,
        date: new Date(),
        from: new Date(),
        to: null,
        seller:'',
        orderStatus: "",
        orders: [],
        purchase: [],
        shop:[],
        items: [
          { name: "All", value: "" },
          { name: "Pending", value: "Pending" },
          { name: "Active", value: "Active" },
          { name: "Accepted", value: "Accepted" },
          { name: "Shipped", value: "Shipped" },
          { name: "Delivered", value: "Delivered" },
        ],
        headers: [
          { text: "Shop Name", value: "_id.shopName", width: "140px" },
          { text: "Phone", value: "_id.contactNumber", width: "80px" },
          { text: "Total Amount", value: "totalAmountSum", width: "80px" },
          { text: "Delivery Charges", value: "deliveryChargeSum", width: "80px" },
          { text: "Order Count", value: "count", width: "80px" },
          // { text: "Actions", value: "_id", width: "50px" },
        ],
      };
    },
    watch: {
      currentPage() {
        this.getData();
      },
      from() {
        this.getData();
      },
      to() {
        this.getData();
      },
      orderStatus() {
        this.getData();
      },
      seller(){
        this.getData();
      }
    },
    beforeMount() {
      this.from = this.from.toISOString().substr(0, 10)
      this.getData();
      this.getSeller()
    },
    methods: {
      getData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/order/summary",
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            limit: this.limit,
            page: this.currentPage,
            fromDate: this.from,
            toDate: this.to,
            orderStatus: this.orderStatus,
            sellerId:this.$route.query.id
            // orderStatus:"Accepted"
          },
        })
          .then((response) => {
            if (response.data.status) {
              this.appLoading = false;
              this.orders = response.data.data;
              this.purchase = response.data.summary;
              this.pages = Math.ceil(response.data.totalLength / this.limit);
            } else {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      getSeller() {
        this.appLoading = true;
        axios({
          url: "/sellerList",
          method: "GET",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.shop = response.data.data;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      formatDate(item) {
        var defDate = new Date(item);
        defDate = defDate.toString().slice(0, 15);
        var today = new Date();
        today = today.toString().slice(0, 15);
  
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday = yesterday.toString().slice(0, 15);
  
        var dt = new Date(item);
        var day = dt.getDate();
        var year = dt.getFullYear();
        dt = dt.toString();
        var strTime = dt.slice(0, 4) + day + " " + dt.slice(4, 7) + " " + year;
  
        if (today == defDate) {
          return "Today";
        } else if (yesterday == defDate) {
          return "Yesterday";
        } else {
          return strTime;
        }
      },
    },
  };
  </script>